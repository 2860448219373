<template>
  <div v-if="loading">
  </div>
  <div v-else class="page mobile">
    <div class="head_new">
      <p class="title">{{info.title}}</p>
      <p v-if="info.subtitle" class="subtitle">—— {{info.subtitle}}</p>
    </div>
    <div v-for="( item,i) in info.content" :key="i">
      <div class="med">
        <div v-for="( line,j) in item"
           :key="j"
           class="li">
          <p  v-if="line.key=='title'" class="light_title">{{line.value}}</p>
          <div v-else-if="line.key=='text'" :class="line.key">
            <p class="duanluo" v-for="(words,k) in line.value" :key="k">{{words}}</p>
          </div>
          <p  data-aos="zoom-up" v-else-if="line.key=='img'" :class="line.key">
            <img :src="line.value" alt="">
          </p>
        </div>
      </div>
    </div>
    <p class="big_button"
         @click="want_loading_app"
         data-aos="slide-up">下载 向量单词 APP</p>
  </div>
</template>
<script>

import axios from 'axios'
import Utils from '../../utils/utils'

export default {
  name: 'note_detail',
  data: function () {
    return {
      loading: true,
      info: {}
    }
  },
  mounted () {
    // Utils.show_snackbar('324234324324')
    console.log(this.$route.query)

    this.get_detail()
  },
  methods: {
    get_detail () {
      var data = { id: this.$route.params.id, sid: this.$route.query.sid }
      axios.post('/note_detail_unlogin/', data)
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.info = res.data.data.info
          this.loading = false
        })
    },
    want_loading_app () {
      Utils.loading_app()
    }
  }

}

</script>
<style  scoped>
.foot {
  position: fixed;
  bottom: 0.8rem;
}

.page {
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 5rem;
}

.el-progress__text span {
  color: rgba(255, 255, 255, 0.8);
}
.mobile .med {
  padding: 0.16rem 0.16rem;
}
.mobile .med .li {
  font-size: 0.36rem;
  padding: 0.1rem 0.2rem;
  font-size: 0.4rem;
}
.duanluo{
  padding: 0.2rem 0rem;
}
.mobile .med img {
  width: 8rem;;
  padding: 0.1rem 0.36rem;
}
.big_button {
  margin: 1rem auto 0.8rem auto;
}
</style>
